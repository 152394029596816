import { AppEnvironment } from '../app/app.environment';

export const environment: AppEnvironment = {
  production: false,
  config: {
    'http://localhost:4200': 'assets/config/config.local.json',
    'https://accountportaal.parro.topicusonderwijs.build': 'assets/config/config.master.json',
    'https://account.development.parnassys.net': 'assets/config/config.development.json',
    fallback: 'assets/config/config.master.json'
  }
};
